<template>
  <div class="following">
    <PageHeader :title="$t('general.followingPage.title')" />

    <InfinityScroll
      v-if="following && following.length"
      :items="following"
      :has-more="hasMoreFollowing"
      @rich-end="loadMore"
    >
      <template #default="{ item }">
        <FollowingUser :user="item.creator" class="following__user" />
      </template>
    </InfinityScroll>
  </div>
</template>

<script>
import FollowingUser from "@/features/components/FollowingUser.vue";
import PageHeader from "@/layout/PageHeader.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";
import InfinityScroll from "@/features/components/InfinityScroll.vue";

const postsCallbackType = {
  setFollowing: "setFollowing",
  updateFollowing: "updateFollowing",
};

export default {
  name: "PageFollowing",
  components: { InfinityScroll, PageHeader, FollowingUser },
  mixins: [waitRequest, validationError],

  computed: {
    ...mapGetters({
      following: "following/following",
      followingMeta: "following/followingMeta",
    }),

    hasMoreFollowing() {
      return Boolean(this.followingMeta?.next_page_url);
    },

    page() {
      return this.followingMeta?.current_page || 1;
    },
  },

  data() {
    return {
      loadFollowingCallback: {
        [postsCallbackType.setFollowing]: this.setCurrentPosts,
        [postsCallbackType.updateFollowing]: this.updateCurrentPosts,
      },
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setFollowing: "following/setFollowing",
      updateFollowing: "following/updateFollowing",
      setMeta: "following/setMeta",
    }),

    initState() {
      this.initialLoadFollowing();
    },

    setCurrentPost(data) {
      this.setFollowing({ following: [data] });
    },

    loadFollowing(successCallbackType = postsCallbackType.setFollowing) {
      if (this.requestInProgress) return;

      const successCallback = this.loadFollowingCallback[successCallbackType];
      const requestUrl = "/follow";

      return this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    async initialLoadFollowing() {
      this.$showSpinner();

      this.loadFollowing(postsCallbackType.setFollowing);
    },

    loadMore() {
      if (this.hasMoreFollowing)
        return this.loadFollowing(postsCallbackType.updateFollowing);
    },

    setCurrentPosts(data) {
      const { current_page, data: following } = data;

      this.setFollowing({ following });
      this.setMeta({ meta: data });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      const following = data.data;

      this.updateFollowing({ following });
      this.setMeta({ meta: data });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.following {
  &__user {
    width: 100%;
    padding: em(15) em(15);
  }
  &__list {
    flex: 1;
  }
}
</style>
